<template>
  <v-dialog v-model="dialog" width="640">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>

    <v-card>
      <v-card-title> {{ $t('token_device_title',{name:userName}) }} </v-card-title>

      <v-card-text>
        <v-text-field
          :value="unexpiredToken"
          hide-details
          readonly
          :loading="isUpdatingToken"
        />
      </v-card-text>

      <v-card-actions>
        <v-row class="mx-0">
          <v-spacer />
          <v-col cols="auto">
            <v-btn
              color="primary"
              outlined
              :disabled="isUpdatingToken"
              @click="createUnexpiredToken"
            >
              <v-icon>mdi-refresh</v-icon>
              {{ $t('update_token') }}
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              color="primary"
              outlined
              :disabled="isUpdatingToken"
              @click="copy(unexpiredToken)"
            >
              <v-icon>mdi-content-copy</v-icon>
              {{ $t('copy') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import copyText from 'clipboard-copy'

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'UserUnexpireTokenDialog',

  data: () => ({
    dialog: false,
    isUpdatingToken: false
  }),

  computed: {
    ...mapState({
      user: state => state.user.user
    }),
    ...mapGetters({
      userName: 'user/userName'
    }),

    unexpiredToken() {
      return this.user?.unexpiredToken
    }
  },

  methods: {
    copy(text) {
      return copyText(text)
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('success_copied')
          })
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
    },
    fetchUser() {
      return this.$store.dispatch('user/fetchUser')
    },
    createUnexpiredToken() {
      this.isUpdatingToken = true
      return this.$store
        .dispatch('user/createUnexpiredToken')
        .then(() => this.fetchUser())
        .finally(() => {
          this.isUpdatingToken = false
        })
    }
  }
}
</script>

<style lang="scss" scoped></style>
